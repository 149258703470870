.container.Gallery {
  .page-title {
    margin-top: 20px;

    font-size: 45px;
    font-weight: 400;
    font-family: 'Crimson Text', serif;
    text-align: center;
  }
}
