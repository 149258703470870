.container.ImageModal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0%;
  left: 0;

  background-color: black;

  .xmark,
  .chevron-left,
  .chevron-right,
  .chevron-up,
  .chevron-down {
    cursor: pointer;
    position: absolute;
    z-index: 1;

    color: white;
  }

  .xmark,
  .chevron-left,
  .chevron-right {
    font-size: 50px;
    opacity: 30%;
    &:hover {
      opacity: 50%;
    }
  }

  .chevron-left,
  .chevron-right {
    top: 50%;
    transform: translateY(-50%);
  }

  .xmark {
    top: 10px;
    right: 20px;
  }

  .chevron-left {
    left: 10px;
  }

  .chevron-right {
    right: 10px;
  }

  .chevron-up,
  .chevron-down {
    right: 20px;
    top: -25px;
    font-size: 25px;
  }

  .chevron-up {
    opacity: 75%;
  }

  .chevron-down {
    opacity: 50%;
    &:hover {
      opacity: 75%;
    }
  }

  img {
    position: relative;
    width: 100%;
    height: 100%;

    object-fit: contain;
    padding: 0 50px;
  }

  .info {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 50px;

    bottom: 0;

    background-color: rgba(0, 0, 0, 0.5);

    .title,
    .description {
      color: rgb(215, 215, 215);
      text-align: center;
      // padding-top: 5px;
    }
    .title {
      font-weight: bold;
      padding-top: 5px;
    }
  }

  .info.closed {
    transform: translateY(50px);
    transition: transform 0.3s ease;
  }

  .info.opened {
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }
}
