.container--header {
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .logo {
    font-size: 25px;
    font-family: 'Raleway', sans-serif;
    font-weight: 200;
    letter-spacing: 1px;
  }
}

// Mobile & Small Tablets
@media only screen and (max-width: 768px) {
  .container--header {
    // height: 50px;
    align-items: flex-start;
  }
}

// Desktop & Large Tablets
@media only screen and (min-width: 769px) {
  .container--header {
    // height: 50px;
    align-items: center;
  }
}
