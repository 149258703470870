.container--Amenities {
  width: 100%;
  height: 100%;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;

  .amenities-box {
    min-width: 320px;
    width: 35%;
    min-height: 50px;
    padding: 10px;

    .title {
      font-size: 45px;
      @media only screen and (max-width: 669px) {
        font-size: 45px;
      }
      font-weight: 500;
      font-family: 'Crimson Text', serif;
      text-align: center;
      padding-bottom: 20px;
    }

    .amenities {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 40px;

      .icons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;

        img {
          margin: auto;
        }
      }
    }

    .footnote {
      font-size: 12px;
      font-weight: 500;
      text-align: center;
      letter-spacing: 1px;
      line-height: 200%;
      padding-top: 30px;
    }
  }

  .image-box {
    min-width: 320px;
    width: 21%;
    min-height: 50px;
    padding: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 10px;
    }
  }
}
