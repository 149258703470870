a {
  text-decoration: none;
  color: inherit;
}

.container--footer {
  height: 75px;
  background-color: rgb(245, 245, 245);
  padding: 15px 0 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .social-media {
    color: rgb(75, 75, 75);
    font-size: 20px;
    display: flex;
    gap: 20px;

    .icon {
      cursor: pointer;
    }

    .icon:hover {
      color: rgb(150, 150, 150);
    }
  }

  .copyright {
    font-size: 13px;
  }
}
