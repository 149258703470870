@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.banner-image {
  max-height: 1000px;
  overflow: hidden;

  img {
    width: 100%;
    animation: fadeIn 2.5s;
  }
}
