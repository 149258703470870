.container--Lake {
  width: 100%;
  height: 100%;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;

  .text-box {
    min-width: 320px;
    width: 20%;
    min-height: 50px;
    padding: 10px;

    .title {
      font-size: 45px;
      @media only screen and (max-width: 669px) {
        font-size: 45px;
      }
      font-weight: 500;
      font-family: 'Crimson Text', serif;
      text-align: right;
      padding-bottom: 20px;
    }
    .description {
      font-size: 15px;
      font-weight: 500;
      text-align: right;
      letter-spacing: 1px;
      line-height: 200%;
    }
  }

  .image-box {
    min-width: 320px;
    width: 21%;
    min-height: 50px;
    padding: 10px;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 10px;
    }
  }
}
