.app {
  min-width: 320px;
  width: 100%;
  min-height: 568px;
  height: 100%;
  position: absolute;
}

// Mobile & Small Tablets
@media only screen and (max-width: 768px) {
  .app {
    .page {
      // 100% - header height - footer height
      min-height: calc(100% - 50px - 75px);
    }
  }
}

// Desktop & Large Tablets
@media only screen and (min-width: 769px) {
  .app {
    .page {
      // 100% - header height - footer height
      min-height: calc(100% - 50px - 75px);
    }
  }
}
