.container.ContactForm {
  min-width: 320px;
  max-width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .contact-image-box {
    min-width: 320px;
    width: 100%;
    min-height: 150px;

    img {
      max-width: 100%;
      max-height: 100%;
      border-radius: 5px;
    }
  }

  .title {
    font-size: 45px;
    font-weight: 500;
    font-family: 'Crimson Text', serif;
    margin: 0;
  }

  .description {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1px;
    text-align: left;
  }

  form {
    width: 100%;

    li {
      list-style: none;
      margin-bottom: 10px;
      clear: both;
    }
    li.half {
      width: 49%;
      margin-left: 2%;
      float: left;
      clear: none;

      &:first-child {
        margin-left: 0%;
      }
    }

    // Overrides global 'user-select: none' inside style.scss
    input,
    textarea {
      user-select: text;
    }

    input[type='text'],
    input[type='email'] {
      border: 1px solid rgb(200, 200, 200);
      width: 100%;
      height: 50px;
      font-size: 16px;
      padding: 0 20px;

      &::placeholder {
        color: rgb(125, 125, 125);
      }
    }
    textarea {
      border: 1px solid rgb(200, 200, 200);
      width: 100%;
      min-height: 150px;
      font-size: 16px;
      padding: 20px;

      &::placeholder {
        color: rgb(125, 125, 125);
      }
    }
    .send-button {
      border: 1px solid rgb(200, 200, 200);
      border-radius: 4px;
      background-color: transparent;
      transition: background-color 0.5s ease;
      font-size: 11px;
      color: rgb(50, 50, 50);
      letter-spacing: 3px;
      padding: 8px 10px;
      float: right;
      cursor: pointer;

      &:hover {
        background-color: rgb(225, 225, 225);
        transition: background-color 0.5s ease;
      }
    }
  }
}
