nav {
  .menu {
    position: absolute;
    width: 100%;
    z-index: 1;

    .menu--nav {
      li {
        list-style: none;
        text-align: center;
        cursor: pointer;
      }
    }
    .menu--social {
      color: rgb(75, 75, 75);
      font-size: 20px;
      display: flex;
      justify-content: center;
      gap: 30px;

      .icon {
        cursor: pointer;
      }
      .icon:hover {
        color: rgb(150, 150, 150);
      }
    }
  }
}

// Mobile & Small Tablets
@media only screen and (max-width: 768px) {
  .menu {
    border: 1px solid rgb(200, 200, 200);
    background-color: white;
    max-width: 360px;
    height: calc(100% - 40px); // 100% - header height
    top: 40px;
    padding: 0 10px;

    .menu--nav {
      li:first-child {
        margin-top: 10px;
      }
      li {
        padding: 5px 0;
      }
      li:hover {
        background-color: rgb(240, 240, 240);
      }
    }

    .menu--social {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 1px solid grey;
    }
  }

  .menu.inactive {
    transform: translateX(-100%);
    transition: transform 0.5s ease-out;
  }

  .menu.active {
    transform: translateX(0%);
    transition: transform 0.5s ease-out;
    position: fixed;
  }

  .shader {
    background-color: black;
    opacity: 0%;
    width: 100%;
    height: calc(100% - 40px); // 100% - header height
    position: absolute;
    top: 40px;
    z-index: -1;
    transition: opacity 0.5s ease, z-index 1.5s ease;
  }

  .menu.active ~ .shader {
    opacity: 50%;
    z-index: 0;
    transition: opacity 0.5s ease;
    position: fixed;
  }
}

// Desktop & Large Tablets
@media only screen and (min-width: 769px) {
  .menu {
    top: 8px;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .menu--nav {
      font-size: 15px;
      display: flex;
      gap: 40px;

      li {
        width: 110%;
      }

      li:hover {
        border-bottom: 1px solid black;
      }

      li.selected {
        border-bottom: 1px solid black;
      }
    }
  }
}
