// Mobile & Small Tablets
@media only screen and (max-width: 768px) {
  .container--MenuButton {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 7px;
    right: 8px;
    cursor: pointer;

    .navicon {
      width: 18px;
      height: 2px;
      background-color: #333;
      position: relative;
      transition: background-color 0.2s ease-out;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .navicon:before,
    .navicon:after {
      content: '';
      width: 100%;
      height: 100%;
      background-color: #333;
      position: absolute;
      transition: all 0.2s ease-out;
    }

    .navicon:before {
      top: -5px;
    }

    .navicon:after {
      top: 5px;
    }

    .navicon.active {
      background-color: transparent;
    }

    .navicon.active:before {
      transform: rotate(45deg);
      top: 0;
    }

    .navicon.active:after {
      transform: rotate(-45deg);
      top: 0;
    }
  }
}

// Desktop & Larger Tablets
@media only screen and (min-width: 769px) {
  .container--MenuButton {
    display: none;
  }
}
