.container.GalleryList {
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

@for $i from 1 through 17 {
  .container.GalleryListItem:nth-child(#{$i}) {
    animation-delay: #{$i * 0.3}s;
  }
}
