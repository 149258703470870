.container--Contact {
  // 100% - header height - footer height
  min-height: calc(1080px - 50px - 75px);

  display: flex;
  flex-direction: row;
  justify-content: center;

  padding: 0 20px;
}
